import React from 'react';
import Head from 'next/head';
import Script from 'next/script';
import { Meta } from '@components/atoms/MetaTags/Meta';
import { Oewa } from '@components/atoms/Oewa/Oewa';
import { Title } from '@components/atoms/Title';
import { Service as ServiceLayout } from '@components/templates/layouts/Service';
import { GetBySlugFullDocument } from '@lib/graphql/generated';
import { fetchExtraPageData } from '@lib/graphql/helpers/fetchExtraPageData';
import { createUrqlClient } from '@lib/graphql/urql';

export function ServicePage({ data }) {
  const { metadata, title, pageContent } = data;
  const script = `<melibo-webchat api-key="5bd1d3f4-4e8f-4844-8596-f54d0a9d1851" mode="embed"></melibo-webchat>`;
  return (
    <ServiceLayout>
      <Oewa pageKey={'Service'} categoryKey={'Sonstiges'} profileKey={'Sonstiges'} />
      <Head>{Meta(metadata)}</Head>
      <div className="mx-auto mt-8 px-5 sm:mt-15 sm:px-10 lg:max-w-6xl">
        <Title level={1} classProps={{ heading: 'text-black z-10 text-4xl lg:text-6xl' }}>
          {title}
        </Title>
        {pageContent?.[0]?.content && (
          <div
            className="py-5 sm:py-8 sm:text-2xl"
            dangerouslySetInnerHTML={{ __html: pageContent?.[0]?.content?.[0]?.text }}
          />
        )}
        <Script src={'https://cdn.melibo.de/5bd1d3f4-4e8f-4844-8596-f54d0a9d1851/bundle.js'} async={true} />
        <div dangerouslySetInnerHTML={{ __html: script }} className={'scrollbar-none'} />
      </div>
    </ServiceLayout>
  );
}

export const serviceSsrLogic = async () => {
  const { ssrQuery, ssrCache } = createUrqlClient();

  const { data } = await ssrQuery({
    query: GetBySlugFullDocument,
    variables: {
      portal: 'tvmedia',
      slug: '/service',
    },
  });

  const page = data?.findBySlug;

  return {
    revalidate: 60 * 60 * 24 * 2, // 2 days
    props: {
      data: { ...page, pageContent: page?.__typename === 'Page' ? await fetchExtraPageData(page?.pageContent) : null },
      slug: 'service',
      urqlState: ssrCache?.extractData() || null,
    },
  };
};
