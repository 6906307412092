import { GetSimpleEntityDocument } from '../generated';
import { ssrQuery } from '../urql';
import { fetchListicleData } from './fetchListicleData';

export async function fetchExtraPageData(pageContent) {
  await Promise.all(
    pageContent.map(async (element) => {
      if (element.__typename === 'PageContentElementHero') {
        element.hero_slides?.map(async (slide) => {
          if (slide.__typename !== 'HeroSlideServices' && slide?.entity?.id) {
            const { data: slideData } = await ssrQuery({
              query: GetSimpleEntityDocument,
              variables: { id: slide.entity.id },
            });
            slide.entityData = JSON.parse(JSON.stringify(slideData?.entity));
            return slide || false;
          }
        });
      } else if (element.__typename === 'PageContentElementListicles') {
        try {
          const { data } = await fetchListicleData(
            element.listicle_type,
            Number(element.limit),
            element.query,
            element.items,
            element.onlyWithAssets,
          );
          element.dataSet = data?.data?.data || data?.data || data || [];
        } catch (err) {
          console.error(err);
        }
      }
      return element;
    }),
  );
  return pageContent;
}
