import React from 'react';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { GetStaticPaths } from 'next/types';
import { AditionTagWithFallback } from '@vgn-medien-holding/vgn-fe-components';
import { getCookie } from 'cookies-next';
import { twMerge } from 'tailwind-merge';
import { RESSORT_ARTICLES_PER_LOAD } from 'tvm-config';
import { AditionInitialization } from '@components/atoms/Adition/AditionInitialization';
import { Meta } from '@components/atoms/MetaTags/Meta';
import { Oewa } from '@components/atoms/Oewa/Oewa';
import { Title } from '@components/atoms/Title';
import * as PageElements from '@components/organisms/PageElements/PageElements';
import { cinemaSsrLogic } from '@components/pages/kino';
import { magazineSsrLogic } from '@components/pages/magazin';
import { ServicePage, serviceSsrLogic } from '@components/pages/service';
import { DefaultOnlyLogo, DefaultNoSpacing, Article as ArticleLayout, Default } from '@components/templates/layouts';
import {
  GetAllArticlesPaginatedDocument,
  GetAllRessortsDocument,
  GetBySlugFullDocument,
  GetRessortByIdDocument,
} from '@src/lib/graphql/generated';
import { decodeOptions } from '@src/utils/urlHelpers';
import { adPositions } from '@lib/adition/adPositions';
import { fetchExtraPageData } from '@lib/graphql/helpers/fetchExtraPageData';
import { createUrqlClient } from '@lib/graphql/urql';
import { useTagManager } from '@lib/hooks/useTagManager';
import { handleRedirect } from '@lib/redirects/redirect';

const SearchPage = dynamic(() => import('@components/pages/suche').then((mod) => mod['SearchPage']));
const RessortPage = dynamic(() =>
  import('@components/organisms/PageContents/RessortPage').then((mod) => mod['Ressort']),
);
const SubscriptionOverviewPage = dynamic(() =>
  import('@components/organisms/PageContents/SubscriptionOverviewPage').then((mod) => mod['SubscriptionOverviewPage']),
);
const CinemaGuide = dynamic(() => import('@components/pages/kino').then((mod) => mod['CinemaGuide']));
const MagazineIndex = dynamic(() => import('@components/pages/magazin').then((mod) => mod['MagazineIndex']));

function Page({ data, slug }) {
  if (slug === '/index') {
    return (
      <DefaultNoSpacing>
        <div>{data && <DefaultPage data={data} />}</div>
      </DefaultNoSpacing>
    );
  }

  if (slug === 'suche') {
    return (
      <Default>
        <SearchPage />
      </Default>
    );
  }

  if (slug === 'kino') {
    return (
      <Default>
        <CinemaGuide data={data} />
      </Default>
    );
  }
  if (slug === 'magazin') {
    return (
      <ArticleLayout>
        <MagazineIndex data={data} />
      </ArticleLayout>
    );
  }

  if (slug === 'service') {
    return <ServicePage data={data} />;
  }

  if (data.__typename === 'SubscriptionOverview') {
    return (
      <DefaultOnlyLogo>
        <SubscriptionOverviewPage data={data} />
      </DefaultOnlyLogo>
    );
  }

  return data.isRessort ? (
    <ArticleLayout>
      <RessortPage data={data} />
    </ArticleLayout>
  ) : (
    <DefaultNoSpacing>
      <DefaultPage data={data} />
    </DefaultNoSpacing>
  );
}

export const DefaultPage = ({ data }) => {
  const { title, show_title, pageContent, oewa } = data;

  const containerStyle = twMerge(
    'text-gray-600 dark',
    pageContent?.[0]?.__typename === 'PageContentElementHero' ||
      pageContent?.[0]?.__typename === 'PageContentElementHeroImages'
      ? ''
      : 'pt-10 mt-24',
  );

  useTagManager({
    event: 'metadata',
    loginStatus: 'nicht eingeloggt',
    seitentyp: 'Seite',
    titel: title,
    assetID: oewa?.id,
    aktualisierungsdatum: data?.updated_at,
    erstellungsdatum: data?.created_at,
    oewaSeitenkontingent: oewa?.page_key || 'RedCont',
    oewaSubkontingent: oewa?.category_key || 'KulturUndFreizeit',
    oewaProfilingkontingent: oewa?.profile_key || 'FilmUndKino',
    cookieAlert: getCookie('OptanonConsent'),
  });

  return (
    <>
      <Oewa
        categoryKey={oewa?.page_key || 'RedCont'}
        pageKey={oewa?.category_key || 'KulturUndFreizeit'}
        profileKey={oewa?.profile_key || 'FilmUndKino'}
      />
      <AditionInitialization tags={adPositions} data={data} />
      <Head>{Meta(data?.metadata)}</Head>
      <div className={containerStyle}>
        {show_title && title && (
          <Title level={1} classProps={{ heading: 'text-center mb-6 dark:text-white' }}>
            {title}
          </Title>
        )}
        {pageContent && <PageBody pageContent={pageContent} />}
      </div>
    </>
  );
};

function PageBody({ pageContent }) {
  let ad_count = 0;
  const router = useRouter();
  return (
    <div className="space-y-16 text-white md:space-y-32">
      {pageContent.map((element, idx) => {
        return (
          <div key={idx}>
            {idx > 1 &&
              (router.pathname == '/' ? idx === 2 || (idx % 3 === 0 && idx > 3) : idx % 3 === 0) &&
              ad_count < (idx > 9 ? 3 : 2) && (
                <div className="my-8 grid place-items-center lg:-mt-20 lg:mb-4">
                  <AditionTagWithFallback
                    tag={adPositions[2]}
                    tagCount={ad_count}
                    fallback={adPositions[20 + ad_count++]}
                    breakpoint={'lg'}
                  />
                </div>
              )}
            {element.headline &&
              element?.__typename !== 'PageContentElementHeroImages' &&
              element?.__typename !== 'PageContentElementEntityAd' && (
                <Title level={element.headline_level} classProps={{ heading: 'pb-4 dark:text-white' }}>
                  {element.headline}
                </Title>
              )}
            {!!PageElements?.[element.__typename] && PageElements?.[element.__typename]?.(element, idx)}
          </div>
        );
      })}

      <div className="mb-4 grid place-items-center lg:-mt-20">
        <AditionTagWithFallback tag={adPositions[2]} fallback={adPositions[23]} breakpoint={'lg'} tagCount={ad_count} />
      </div>
    </div>
  );
}

export const getStaticPaths: GetStaticPaths = async () => {
  return {
    paths: ['/index', '/suche', '/kino', '/abo', '/magazin', '/service'],
    fallback: 'blocking',
  };
};

export const getStaticProps = async ({ params }) => {
  const { ssrQuery, ssrCache } = createUrqlClient();
  const options = decodeOptions(params?.categoryOrPage);

  let result;

  if (options.slug === 'suche') {
    return {
      props: {
        slug: 'suche',
      },
    };
  }

  if (options.slug === 'kino') {
    const result = await cinemaSsrLogic();
    return result;
  }

  if (options.slug === 'magazin') {
    const result = await magazineSsrLogic();
    return result;
  }

  if (options.slug === 'service') {
    const result = await serviceSsrLogic();
    return result;
  }

  const slug = options?.slug === 'index' ? '/home' : options?.slug;

  const { data: findBySlugData } = await ssrQuery({
    query: GetBySlugFullDocument,
    variables: {
      portal: 'tvmedia',
      slug: slug,
      coverImageWidth: 3840,
      coverImageHeight: 2160,
      coverImageFit: 'crop_focal',
    },
  });

  const data = findBySlugData?.findBySlug;

  if (!data) return { notFound: true };

  const type = data?.__typename;

  if (type === 'Ressort') {
    const { data: ressortsData } = await ssrQuery({
      query: GetAllRessortsDocument,
      variables: { portal: 'tvmedia' },
    });
    const ressorts = ressortsData?.ressorts;

    const activeRessort = ressorts.find((e) => e.slug === options.slug);
    const isRessort = !!activeRessort;

    await ssrQuery({
      query: GetAllArticlesPaginatedDocument,
      variables: {
        portal: 'tvmedia',
        ressort: activeRessort?.id,
        first: RESSORT_ARTICLES_PER_LOAD,
        page: 1,
        orderBy: 'updated_at',
        orderDirection: 'DESC',
      },
    });

    const { data: ressortData } = await ssrQuery({
      query: GetRessortByIdDocument,
      variables: {
        portal: 'tvmedia',
        id: activeRessort.id,
      },
    });

    result = { ...data, isRessort, activeRessort, ressorts, oewa: ressortData.ressort.oewa };
  } else {
    result = {
      ...result,
      ...data,
      pageContent: data?.__typename === 'Page' ? await fetchExtraPageData(data?.pageContent) : null,
    };
  }

  function checkMetadataRedirect(): [number, string] {
    if (
      data?.__typename !== 'Redirect' &&
      data?.__typename !== 'Article' &&
      data?.__typename !== 'SubscriptionOverview' &&
      data?.metadata?.redirect
    ) {
      return [null, data?.metadata?.redirect];
    }
    return null;
  }

  const redirect: [number, string] =
    data?.__typename === 'Redirect' && data?.redirect_type && data?.redirect_target
      ? [data?.redirect_type, data?.redirect_target]
      : checkMetadataRedirect();

  // Handle redirect result
  if (redirect) {
    return handleRedirect(...redirect);
  }

  const props = {
    data: result,
    slug: options?.slug || '/home',
    urqlState: null,
  };

  if (type === 'Ressort') {
    props.urqlState = ssrCache.extractData();
  }

  return {
    props,
  };
};

export default Page;
